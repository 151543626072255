import React from "react";
import css from './pricingModel.module.scss';
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";


const PricingModel = () => {
    const routes = getRoutes();
    const title = routes.Offering.sections.pricingModel.title;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.title}>{title}</div>
                <Text textIsBold={false}>
                    <span className={css.textBold}>TimberID</span> cost-effective acquisition model makes it affordable
                    for SME’s and Enterprises,
                    <br/>
                    an annual license access fee is tailored accordingly and Pay-As-You-Use variable system usage fees.
                </Text>
            </div>
        </div>
    )
};

export default PricingModel;
