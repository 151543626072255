import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './offeringSaasPlatform.module.scss';
import {getRoutes} from "../../shared/routes";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import ListItem from "../../shared/ListItem/ListItem";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_saas"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const OfferingSaasPlatform = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Offering.sections.saas.title;

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>
                <div className={css.title}>{title}</div>
            </SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'saas.png', 'SaaS')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'}>
                <SectionDesktopTitle>
                    <div className={css.title}>{title}</div>
                </SectionDesktopTitle>
                <div>
                    <ListItem
                        list={['Fixed annual license recurring access fee. ', 'Flexible monthly platform usage resharing fee, including:']}/>
                </div>
                <div style={{paddingLeft: 24, margin: '6px 0 6px 0 '}}>
                    <ListItem
                        style={{padding: '1px'}}
                        list={['File transfer and storage', 'File processing', 'License']}/>
                </div>
                <div>
                    <ListItem
                        list={['Customisation']}/>
                </div>
                <div style={{paddingLeft: 24, margin: '6px 0 6px 0 '}}>
                    <ListItem
                        style={{padding: '1px'}}
                        list={['System functionality modifications ', 'Integrations']}/>
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default OfferingSaasPlatform;
